import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="rmchocolates_logo.jpg" className="App-logo" alt="logo" />
        <p>
          Rm chocolates - Ručná výroba prémiových čokoládových výrobkov.
          <a href="https://www.facebook.com/rmchocolates.2015">Facebook</a>
        </p>
      </header>
    </div>
  );
}

export default App;
